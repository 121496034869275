import React from "react";

const TeamMateCard = (props) => {
  return (
    <div className="col-12 col-md-6 col-xl-3">
        <div className={props.classStyle}>
            <div className="member-img bg-cover bg-center" style={{backgroundImage: `url(${props.bgImgUrl})`}}>
            </div>
            <div className="member-bio">
                <h4>{props.name}</h4>
                <p>{props.role}</p>
            </div>
            <div className="social-profile">
                <a href="#/"><i className="fab fa-facebook-f"></i></a>
                <a href="#/"><i className="fab fa-twitter"></i></a>
                <a href="#/"><i className="fab fa-behance"></i></a>
                <a href="#/"><i className="fab fa-youtube"></i></a>
            </div>
        </div>
    </div>
  );
};

export default TeamMateCard;