import React from "react";
import CallToAction from "../components/general/callToAction";

const Refund_policy = () => {
  return (
  <>  
      <section className="page-banner-wrap bg-cover" style={{backgroundImage: "url('assets/img/about-banner.webp')"}}>
        <br />
        <br />
        <br />
        <div className="banner-text">Refund</div>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-12 col-12">
                    <div className="page-heading text-white">
                        <div className="page-title">
                            <h1>Refund Policy</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    

    <section className="case-study-wrapper section-padding">
        <div className="container">
            <div className="align-items-center">
                    <div className="section-title mb-4">
                        <h1>Our Refund Policy</h1>
                    </div>
                    <div className="container">
                    <p className="mb-4">At Abia Tech Hub, we are committed to providing our members with the best possible support for their tech-related ventures. We understand that there may be circumstances that require a refund for Services or products purchased from us. This refund policy outlines our commitment to our members and the procedures we follow to ensure a fair and consistent refund process.</p>

                    <h3>1. Refund Eligibility</h3>
                    <p className="mb-4">
                      Our refund policy applies to all Services and products purchased from Abia Tech Hub. To be eligible for a refund, the request must be made within 14 days of the original purchase date. Refunds are only granted for unused Services or products.
                    </p>

                    <h3>2. Refund Procedures</h3>
                    <p className="mb-4">
                      To request a refund, members must contact Abia Tech Hub customer support by email or phone. Our team will review the request and process the refund within 7-10 business days. Refunds will be issued using the same payment method used for the original purchase. If the payment was made by credit card, the refund will be issued to the same card.
                    </p>

                    <h3>3. Non-Refundable Services</h3>
                    <p className="mb-4">
                      The following Services and products are non-refundable:
                    <ul>
                      <li>Membership fees</li>
                      <li>Event tickets</li>
                      <li>Consulting Services</li>
                    </ul>
                    </p>

                    <h3>4. Cancellation Policy</h3>
                    <p className="mb-4">
                      For membership cancellations, members must provide written notice at least 30 days prior to the next billing cycle to avoid being charged for the next month. For event cancellations, members must provide at least 48 hours notice to receive a refund.
                    </p>

                    <h3>5. Exceptional Circumstances</h3>
                    <p className="mb-4">
                      In exceptional circumstances, such as technical issues or service interruptions, Abia Tech Hub may provide a refund or credit to the member's account. These circumstances will be evaluated on a case-by-case basis.
                    </p>

                    <p>
                      We are committed to providing the best possible customer service and experience to our members. If you have any questions or concerns regarding our refund policy, please contact our customer support team.
                    </p>

                    </div>
                </div>
            </div>
      </section>

      <CallToAction />
  </>
  );
}

export default Refund_policy;