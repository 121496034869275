import React from "react";
import { useState } from 'react';
import axios from 'axios';

function Contact() {
    const [formData, setFormData] = useState({
      fname: '',
      email: '',
      phone: '',
      subject: '',
      message: ''
    });
  
    const handleChange = (event) => {
      setFormData({ ...formData, [event.target.id]: event.target.value });
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
        //   axios.post('/api/contact.php', formData, {
        //     headers: {
        //       'Content-Type': 'application/json'
        //     }
        //   })
         axios.post('/api/contact.php', JSON.stringify(formData), {
            headers: {
            'Content-Type': 'application/json'
            }
        })
        .then((response) => {
          console.log(response.data); // Handle successful form submission
        })
        .catch((error) => {
          console.error(error); // Handle error
        });
    };

  return (
  <>
     <section className="page-banner-wrap bg-cover" style={{backgroundImage: "url('assets/img/contact-banner.webp')"}}>
        <br />
        <br />
        <br />
        <div className="banner-text">contact</div>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-12 col-12">
                    <div className="page-heading text-white">
                        <div className="page-title">
                            <h1>Contact Us</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="contact-page-wrap section-padding">
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-contact-card card1">
                        <div className="top-part">
                            <div className="icon">
                                <i className="fal fa-envelope"></i>
                            </div>
                            <div className="title">
                                <h4>Email Address</h4>
                                <span>Send mail anytime</span>
                            </div>
                        </div>
                        <div className="bottom-part">                            
                            <div className="info">
                                <p>hello@abiatechhub.com</p>
                            </div>
                            <div className="icon">
                                <i className="fal fa-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-contact-card card2">
                        <div className="top-part">
                            <div className="icon">
                                <i className="fal fa-phone"></i>
                            </div>
                            <div className="title">
                                <h4>Phone Number</h4>
                                <span>Call us anytime</span>
                            </div>
                        </div>
                        <div className="bottom-part">                            
                            <div className="info">
                                <p>+234 805 274 9600</p>
                            </div>
                            <div className="icon">
                                <i className="fal fa-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-contact-card card3">
                        <div className="top-part">
                            <div className="icon">
                                <i className="fal fa-map-marker-alt"></i>
                            </div>
                            <div className="title">
                                <h4>Office Address</h4>
                                <span>Visit us anytime</span>
                            </div>
                        </div>
                        <div className="bottom-part">                            
                            <div className="info">
                                <p> 6, Warri street Umuahia, Abia state.</p>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-lg-12">
                    <div className="contact-map-wrap">
                        <div id="map">
                          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3971.2187863554536!2d7.491104674226888!3d5.534532733866766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1042dcc55f4f0f9d%3A0xf30a6c9bcfab7dfa!2sAbia%20Tech%20Hub!5e0!3m2!1sen!2sng!4v1685530733902!5m2!1sen!2sng"
                          frameBorder="0"
                          style={{ border: "0", width: "100%"}}
                          allowFullScreen=""
                          aria-hidden="false"
                          loading="lazy"
                          tabIndex="0"
                          referrerpolicy="no-referrer-when-downgrade"
                          title="Google Maps"
                        ></iframe>  
                        </div>
                    </div>
                </div>
            </div>

            <div className="row section-padding pb-0">
                <div className="col-12 text-center mb-20">
                    <div className="section-title">
                        <p>send us message</p>
                        <h1>Don’t Hesitate To Contact Us <br /> Say Hello</h1>
                    </div>
                </div>

                <div className="col-12 col-lg-12">
                    <div className="contact-form">                                                        
                        <form action="/api/contact.php" className="row conact-form" onSubmit={handleSubmit}>
                            <div className="col-md-6 col-12">
                                <div className="single-personal-info">
                                    <label for="fname">Full Name</label>
                                    <input type="text" id="fname" name="fname" placeholder="Enter Name" value={formData.fname} onChange={handleChange} />                                         
                                </div>
                            </div>                            
                            <div className="col-md-6 col-12">
                                <div className="single-personal-info">
                                    <label for="email">Email Address</label>
                                    <input type="email" id="email" name="email" placeholder="Enter Email Address" value={formData.email} onChange={handleChange} />                                         
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="single-personal-info">
                                    <label for="phone">Phone Number</label>
                                    <input type="text" id="phone" name="phone" placeholder="Enter Number" value={formData.phone} onChange={handleChange} />                                         
                                </div>
                            </div>                                      
                            <div className="col-md-6 col-12">
                                <div className="single-personal-info">
                                    <label for="subject">Subject</label>
                                    <input type="text" id="subject" name="subject" placeholder="Enter Subject" value={formData.subject} onChange={handleChange} />                                         
                                </div>
                            </div>                                      
                            <div className="col-md-12 col-12">
                                <div className="single-personal-info">
                                    <label for="message">Enter Message</label>
                                    <textarea id="message" name="message" placeholder="Enter message" value={formData.message} onChange={handleChange} ></textarea>                                        
                                </div>
                            </div>                                      
                            <div className="col-md-12 col-12 text-center">
                                <button type="submit" className="theme-btn" onChange={handleSubmit}>send message <i className="fas fa-arrow-right"></i></button>
                            </div>                                      
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    
    <section className="cta-banner">
        <div className="container-fluid bg-cover section-bg" style={{backgroundImage: "url('assets/img/cta_bg1.png')"}}> 
            <div className="cta-content">
                <div className="row align-items-center">
                    <div className="col-xl-7 text-white col-12 text-center text-xl-left">
                        <h1>Ready To Get Free Consulations For <br /> Any Kind Of IT Solutions ? </h1>
                    </div>
                    <div className="col-xl-5 col-12 text-center text-xl-right">
                        <a href="/contact" className="theme-btn mt-4 mt-xl-0">Get a quote <i className="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section> 
  </>
  );
}

export default Contact;