import React from "react";

const GeneralHero = (props) => {
  return (
    <section className="page-banner-wrap bg-cover" style={{backgroundImage: "url('assets/img/about-banner.webp')"}}>
        <br />
        <br />
        <br />
        <div className="banner-text">{props.bgText}</div>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-12 col-12">
                    <div className="page-heading text-white">
                        <div className="page-title">
                            <h1>{props.theme}</h1>
                        </div>
                    </div>

                    {/* <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                          <li className="breadcrumb-item active" aria-current="page">{props.subTheme}</li>
                        </ol>
                    </nav> */}
                </div>
            </div>
        </div>
    </section>
  );
};

export default GeneralHero;

<GeneralHero bgText={"Services"} theme={"Services"} subTheme={"Services"} /> 