import React from "react";
import OurServiceItem from "../general/ourServiceItem";

const ItSolutions = () => {
  return (
    <section className="Services-wrapper service-1 section-padding section-bg">
        <div className="container">
            <div className="row mb-50">
                <div className="col-12 col-lg-12">
                    <div className="section-title text-white text-center">
                        <p>Popular IT Services</p>
                        <h1>Our Professional Solutions <br />
                            For IT Business</h1>
                    </div>
                </div>
            </div>

            <div className="row">

                <OurServiceItem imgUrl={"assets/img/icon/sicon1.png"} caption={"Digitizing Your Business"} description={"With our Digitize Your Business (DYB) package, we can get your business online in just one click. This package includes Web Design, Social Media Management, Ads management, Marketing and Sales Campaigns. Sign up today and Triple your sales!"} />

                
                <OurServiceItem
                imgUrl={"assets/img/icon/sicon2.png"}
                caption={"Co-Working Space"}
                description={<p className="mb-4">Your small business can have a professional business address to call home with dedicated desks and private offices for bigger companies. Sign up for our co-working space to get started.</p>}
                button={
                    <a
                    href="https://academy.abiatechhub.com/"
                    className="theme-btn mt-5"
                    style={{ display: "inline", justifyContent: "center" }}
                    >
                    Workspace <i className="fas fa-arrow-right"></i>
                    </a>
                }
                />


                <OurServiceItem imgUrl={"assets/img/icon/sicon3.png"} caption={"Social Media Management"} description={"Social media is the next gold spot for closing sales. But how do you stand out in the crowd? How do you convince people to buy? Abia Tech Hub has all these answers, we can help you close sales, increase engagement and improve your brand communications as a business or an individual."} />


                <OurServiceItem imgUrl={"assets/img/icon/sicon4.png"} caption={"Google and Facebook Ads"} description={"Sales is the life source of your business. To increase sales, you need to be visible to your target audience. At Abia Tech Hub, our sales experts can help you connect to more customers using Google ADs. Reach out today, let’s get the world to see what you do!"} />

            </div>
        </div>
    </section>
  );
};

export default ItSolutions;